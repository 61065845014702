<template>
  <div class="v-activity">
    <Topbar topbar-component="TopbarActivity" />

    <div class="v-activity__wrapper">
      <div class="v-activity__content-wrapper">
        <ActivityContent />
        <ActivityNavigation />
      </div>
    </div>
  </div>
  <Curtain />
</template>

<script>
import { computed, onMounted, watch } from 'vue'
import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'

import { useTenant, useStructures, useContents, useLocalizations } from '@/core'
import useActivity from '@/composables/useActivity'

import Topbar from '@/components/Topbar'
import Curtain from '@/components/Curtain'
import ActivityContent from '@/components/ActivityContent'
import ActivityNavigation from '@/components/ActivityNavigation'
import useTranslations from '@/composables/useTranslations'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Activity',

  components: {
    Topbar,
    ActivityContent,
    ActivityNavigation,
    Curtain,
  },

  setup() {
    const route = useRoute()
    const { tenant } = useTenant()
    const {
      activityTitle,
      activity,
      activityChildrens,
      setActivity,
    } = useActivity()
    const { fetchObject } = useContents()
    const activitySlugPath = computed(
      () =>
        `${tenant.value.slug}/arenas/${route.params.arena}/${route.params.activity}`,
    )
    const arenaSlugPath = computed(
      () => `${tenant.value.slug}/arenas/${route.params.arena}`,
    )
    const { fetchStructuresChildren, fetchStructuresNode } = useStructures()
    const { isFallbackLanguage, gqlContentQuery } = useTranslations()
    const { locale } = useI18n()
    const { fetchStructuresLocalization } = useLocalizations()
    useHead({
      title: computed(() => `${activityTitle.value} - ${tenant.value.name}`),
    })
    watch(
      () => route.params.activity,
      () => initActivity(),
    )
    const initActivity = async () => {
      await fetchStructuresNode(arenaSlugPath.value)
      await fetchStructuresChildren(arenaSlugPath.value, {
        limit: 1000,
        filter: '{"type": "DIRECTORY"}',
      })
      await fetchStructuresNode(activitySlugPath.value)
      await fetchStructuresChildren(activitySlugPath.value, {
        limit: 1000,
        filter: '{"type": "OBJECT"}',
      })

      setActivity(activitySlugPath.value)

      activityChildrens.value.forEach(ci => {
        if (ci.id && !isFallbackLanguage.value) {
          try {
            fetchStructuresLocalization(ci.id, locale.value)
          } catch {
            //
          }
        }
        if (ci.contentId) {
          if (isFallbackLanguage.value) {
            fetchObject(ci.contentId)
          } else {
            gqlContentQuery(ci.contentId)
          }
        }
      })
    }

    onMounted(async () => {
      initActivity()
    })

    return {
      activity,
      activityTitle,
    }
  },
}
</script>

<template>
  <div
    class="c-activity-content"
    :class="[`c-activity-content--${contentType}`]"
  >
    <div class="c-activity-content__wrapper">
      <ContentRenderer
        v-if="getCurrentElement"
        :content-items="contentItems"
        @contentCompleted="onContentCompleted"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

import ContentRenderer from '@/components/ContentRenderer'
import useActivity from '@/composables/useActivity'

export default {
  components: {
    ContentRenderer,
  },

  setup() {
    const { getCurrentElement, setElementComplete } = useActivity()
    const contentType = computed(
      () => getCurrentElement.value && getCurrentElement.value.contentType,
    )
    const contentItems = computed(() => [getCurrentElement.value])

    const onContentCompleted = () => {
      setElementComplete()
    }

    return {
      contentItems,
      contentType,
      getCurrentElement,
      onContentCompleted,
    }
  },
}
</script>

<template>
  <div class="c-activity-navigation">
    <div class="c-activity-navigation__wrapper">
      <TheButton
        v-if="hasPrevElement"
        class="c-activity-navigation__prev"
        type="transparent"
        icon="arrow-left"
        iconPos="left"
        @click="prevElement"
      >
        {{ $t('ACTIVITY_NAVIGATION_PREV') }}
      </TheButton>
      <TheButton
        class="c-activity-navigation__next"
        type="primary"
        icon="arrow-right"
        iconPos="right"
        @click="nextElement"
        :disabled="!isCompletedElement"
      >
        {{ $t('ACTIVITY_NAVIGATION_NEXT') }}
      </TheButton>
    </div>
    <div class="c-activity-navigation__continue-wrapper">
      <TheButton
        class="c-activity-navigation__continue"
        type="primary"
        @click="nextElement"
        :disabled="!isCompletedElement"
      >
        {{ continueText }}
      </TheButton>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useActivity from '@/composables/useActivity'
import TheButton from '@/components/TheButton'

export default {
  components: {
    TheButton,
  },

  setup() {
    const { t } = useI18n()
    const {
      hasPrevElement,
      prevElement,
      nextElement,
      isLastElement,
      isCompletedElement,
    } = useActivity()
    const continueText = computed(() =>
      isLastElement
        ? t('ACTIVITY_NAVIGATION_CONTINUE')
        : t('ACTIVITY_NAVIGATION_FINISH'),
    )

    return {
      continueText,
      hasPrevElement,
      prevElement,
      nextElement,
      isCompletedElement,
    }
  },
}
</script>
